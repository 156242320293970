



































































import Vue from 'vue'
import axios from 'axios'

import ImageOnLoad from './components/image-on-load.vue'

import { QuestionData, Question, Answer, Result, Colors } from './data/questions'

declare global {
  interface Window {
    _questions_data_url: string | undefined | null
  }
}

import questionData from './data/questions.json'

const questionDataURL = window._questions_data_url

export default Vue.extend({
  data(): {
    colors: Colors | null,
    questions: Question[]
    startQuestionId: number
    currentQuestionId: number
    points: number
    completed: boolean
    loading: boolean
    result: undefined | Result
    results: Result[]
  } {
    return {
      loading: true,
      questions: [],
      startQuestionId: 0,
      currentQuestionId: 0,
      points: 0,
      completed: false,
      result: undefined,
      results: [],
      colors: null
    }
  },
  computed: {
    currentQuestion: function (): Question | undefined {
      return this.questions.find((question) => question.id === this.currentQuestionId)
    }
  },
  mounted: function () {
    if (questionDataURL) {
      axios.get(questionDataURL).then(({data}: { data: QuestionData}) => {
        this.questions = data.questions
        this.startQuestionId = data.startQuestionId
        this.currentQuestionId = data.startQuestionId
        this.results = data.results
        this.colors = data.colors

        this.loading = false
      }, () => this.loading = false)
    } else {
      console.warn('APP is using default questions!')

      this.questions = questionData.questions
      this.startQuestionId = questionData.startQuestionId
      this.currentQuestionId = questionData.startQuestionId
      this.results = questionData.results
      this.colors = questionData.colors

      this.loading = false
    }

  },
  methods: {
    selectAnswer: function (event: any) {
      const id = parseInt(event.target.dataset.id, 10)
      if (!id || isNaN(id)) {
        return
      }
      const answer = this.currentQuestion!.answers.find((answer) => id === answer.id)!
      this.points += answer.points
      if (answer.nextQuestionId) {
        this.currentQuestionId = answer.nextQuestionId
      } else {
        this.result = this.results.find((result) => result.points.indexOf(this.points) > -1)
        this.completed = true
      }
    },
    reset: function () {
      this.points = 0
      this.currentQuestionId = this.startQuestionId
      this.completed = false
    }
  },
  components: {
    ImageOnLoad
  }
});
