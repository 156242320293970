











import Vue from 'vue'

export default Vue.extend({
  name: 'image-on-load',
  props: {
    'imageClass': String,
    'src': String,
    'alt': String,
    'maxWidth': String,
    'maxHeight': String
  },
  data: function () {
    return {
      isImageLoaded: false,
      easeIn: false,
      styles: {
        maxHeight: this.maxHeight || 'auto',
        maxWidth: this.maxWidth || 'auto',
      },
      imageWorker: new Image
    }
  },
  mounted: function () {
    this.imageWorker.onload = () => {
      setTimeout(() => {
        this.isImageLoaded = true
      }, 200)
      setTimeout(() => {
        this.easeIn = true;
      }, 300)
    }
    this.imageWorker.src = this.src;

    if (this.imageWorker.complete) {
      this.isImageLoaded = true
      setTimeout(() => {
        this.easeIn = true;
      })
    }
  }
})
